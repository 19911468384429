import AxiosBase from './axios';
import {AxiosRequestConfig, AxiosRequestHeaders} from 'axios';
import {services} from '../';
import {store} from '../../store';
import {ApiResult} from '../../models'
import {BASE_URL} from "../../shared/constUrl";

/**
 * Сервис, отвечающий за обращение к API.
 */
class AxiosService {
    /**
     * Базовый экземпляр Axios
     */
    private axios: AxiosBase;

    /**
     * Обратный вызов для установки состояния загрузки
     * @param loading Возвращает флаг загрузки
     */
    private onLoading = (loading: boolean) => {
        store.loaderStore.setLoading(loading);
    };

    /**
     * Обратный вызов для возврата токена доступа
     * @returns Возвращает токен доступа
     */
    private getAccessToken = () => {
        return services.tokenService.accessToken;
    };

    /**
     * Обратный вызов для возврата токена обновления
     * @returns возвращает токен обновления
     */
    private getRefreshToken = () => {
        return services.tokenService.refreshToken;
    };

    /**
     * Обратный вызов для возврата токена обновления
     * @returns возвращает токен обновления
     */
    private getUserId = () => {
        return store.userStore.userId;
    };


    /**
     * Обратный вызов срабатывает при успешной аутентификации
     * @param access_token токен доступа
     * @param refresh_token токен обновления
     */
    private onSignInSuccess = (access_token: string, refresh_token: string) => {
        services.tokenService.setTokens(access_token, refresh_token);
        store.userStore.isAuthorized = true;
    };

    /**
     * Обратный вызов срабатывает при ошибке аутентификации
     */
    private onSignInFailed = () => {
        services.tokenService.removeTokens();
        store.userStore.isAuthorized = false;
    };

    /**
     * Конструктор сервиса axios
     */
    constructor() {
        this.axios = new AxiosBase(
            BASE_URL,
            this.onSignInSuccess,
            this.onSignInFailed,
            this.getRefreshToken,
            this.getAccessToken,
            this.getUserId,
            this.onLoading,
        );
    }

    /**
     * Выполнить POST запрос
     * @param url URL адрес
     * @param body Тело запроса
     * @param headers Заголовки запроса
     */
    post<T>(url: string, body?: any, headers?: AxiosRequestHeaders): Promise<ApiResult<T>> {
        this.onLoading(true);
        return headers
            ? this.axios.post(url, body, headers)
            : this.axios.post(url, body);
    }

    /**
     * Выполнить GET запрос
     * @param url URL адрес
     * @param queryParams Параметры запроса
     * @param headers Заголовки запроса
     */
    get<T>(url: string, queryParams: any = undefined, headers?: AxiosRequestHeaders): Promise<ApiResult<T>> {
        this.onLoading(true);
        const config: AxiosRequestConfig = {
            params: queryParams,
            headers: headers
        };

        return this.axios.get(url, config);
    }

    /**
     * Выполнить DELETE запрос
     * @param url URL адрес
     * @param body Тело запроса
     * @param headers Заголовки запроса
     */
    delete<T>(url: string, body?: any, headers?: AxiosRequestHeaders): Promise<ApiResult<T>> {
        this.onLoading(true);
        return headers
            ? this.axios.delete(url, body, headers)
            : this.axios.delete(url, body);
    }

    /**
     * Выполнить PATCH запрос
     * @param url URL адрес
     * @param body Тело запроса
     * @param headers Заголовки запроса
     */
    patch<T>(url: string, body?: any, headers?: AxiosRequestHeaders): Promise<ApiResult<T>> {
        this.onLoading(true);
        return headers
            ? this.axios.patch(url, body, headers)
            : this.axios.patch(url, body);
    }

    /**
     * Выполнить PUT запрос
     * @param url URL адрес
     * @param body Тело запроса
     * @param headers Заголовки запроса
     */
    put<T>(url: string, body?: any, headers?: AxiosRequestHeaders): Promise<ApiResult<T>> {
        this.onLoading(true);
        return headers
            ? this.axios.put(url, body, headers)
            : this.axios.put(url, body);
    }

    /**
     * Метод для загрузки файла
     * @param url URL адрес
     * @param formData Данные формы (файл и дополнительные параметры)
     */
    async uploadFile(url: string, formData: FormData): Promise<void> {
        this.onLoading(true);
        await this.axios.uploadFile(url, formData);
    }

    /**
     * Скачивание файла
     * @param targetUrl адрес для скачивания файла
     * @param defaultFileName имя файла по умолчанию
     */
    async downloadFile(targetUrl: string, defaultFileName = 'downloaded-file.zip') {
        this.onLoading(true);
        const response = await this.axios.getFile(targetUrl, {
            responseType: 'blob',
            contentType: 'application/octet-stream',
            withCredentials: true
        });
        const contentDisposition = response.headers['content-disposition'];
        let fileName = defaultFileName;
        const fileNameMatch = contentDisposition.match(/filename\*?=([^;]*)/i);
        if (fileNameMatch && fileNameMatch[1]) {
            // Если есть значение после '=', оно может быть закодировано в URL
            fileName = decodeURIComponent(fileNameMatch[1].replace(/['"]/g, ''));
        }
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
    }
}

export default AxiosService;