import React, {useCallback, useEffect, useState} from "react";
import {Layout} from "../../../containers/Layout";
import {Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow} from "@fluentui/react-components";
import {JsonProfilePageDto} from "../../../models/admin/jsonProfiles/JsonProfilePageDto";
import {useServices} from "../../../hooks";
import {dateToMskString} from "../../../utils/DateUtils";

/**
 * Страница управления профилями пользователей
 * @constructor
 */
const JsonProfilesPage: React.FC = () => {
    /**
     * Именования столбцов таблицы
     */
    const columns = [
        {columnKey: "userId", label: "User ID"},
        {columnKey: "userName", label: "Username"},
        {columnKey: "version", label: "Version"},
        {columnKey: "uploadTime", label: "Uploaded At"},
        {columnKey: "actions", label: "Actions"},
    ];

    /**
     * Значения по умолчанию
     */
    const defaults: JsonProfilePageDto = {
        profiles: [],
        paging: {
            pageNumber: 1,
            pageSize: 50,
            totalPages: 1,
            totalItems: 0
        }
    };

    const [profilesDto, setProfilesDto] = useState<JsonProfilePageDto>(defaults);
    const services = useServices();

    const updateJsonProfilesList = useCallback(async () => {
        try {
            const response = await services.adminJsonProfilesService.getList();
            setProfilesDto(response);
        } catch (error: any) {
            console.error(error);
        }
    }, [services]);

    useEffect(() => {
        updateJsonProfilesList();
    }, [updateJsonProfilesList]);

    const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>, userId: string) => {
        const file = event.target.files?.[0];
        if (!file) {
            alert('Выберите файл для загрузки');
            return;
        }

        const confirm = window.confirm(`Вы уверены, что хотите загрузить файл для пользователя ${userId}?`);
        if (!confirm) {
            return;
        }

        const formData = new FormData();
        formData.append('file', file)

        try {
            await services.adminJsonProfilesService.uploadFile(formData, userId);
            alert('Файл успешно загружен');
            await updateJsonProfilesList(); // Обновляем список после успешной загрузки
        } catch (error) {
            console.error('Ошибка загрузки файла:', error);
            alert('Произошла ошибка при загрузке файла');
        }
    };

    return (
        <Layout pageTittle={"JSON Profiles"}>
            <div>
                <Table
                    role="grid"
                    aria-label="Table with grid keyboard navigation"
                    style={{minWidth: "620px"}}>

                    <TableHeader as={"div"}>
                        <TableRow as={"div"}>
                            {columns.map((column) => (
                                <TableHeaderCell as={"div"} key={column.columnKey}>
                                    {column.label}
                                </TableHeaderCell>
                            ))}
                        </TableRow>
                    </TableHeader>
                    <TableBody as={"div"}>
                        {profilesDto.profiles.map((item) => (
                            <TableRow as={"div"} key={item.userId}>
                                <TableCell as={"div"}>{item.userId}</TableCell>
                                <TableCell as={"div"}>{item.userName}</TableCell>
                                <TableCell as={"div"}>{item.version}</TableCell>
                                <TableCell as={"div"}>{dateToMskString(new Date(item.updatedAt))}</TableCell>
                                <TableCell as={"div"}>
                                    <input
                                        title="file"
                                        type="file"
                                        onChange={(event) => handleFileUpload(event, item.userId)}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
        </Layout>
    );
};

/**
 * Имя отображаемое при отладке
 */
JsonProfilesPage.displayName = "JsonProfilePage";

export default JsonProfilesPage;