export const BASE_URL = 'https://api.acrstealer.com';

//export const BASE_URL = 'http://localhost:8511';

const URL_PREFIX = "";
/**
 * Список логов
 */
export const LOG_LIST_URL = URL_PREFIX + "/log-list";

/**
 * Список cookies
 */
export const COOKIE_LIST_URL = URL_PREFIX + "/cookies";

/**
 * Страница профиля пользователя
 */
export const PROFILE_URL = URL_PREFIX + "/profile";

/**
 * Страница изменения пароля
 */
export const CHANGE_PASSWORD_URL = URL_PREFIX + "/change-password";

/**
 * Страница загрузок пользователя
 */
export const DOWNLOADS_URL = URL_PREFIX + "/downloads";
/**
 * Страница билдера
 */
export const BUILDER_URL = URL_PREFIX + "/builder";

/**
 * Страница восстановления кукисов
 */
export const RESTORE_COOKIES_URL = URL_PREFIX + "/restore-cookies";

/**
 * Страница оповещений телеграм
 */
export const TELEGRAM_NOTIFICATION_URL = URL_PREFIX + "/telegram-notification";

/**
 * Страница оповещений телеграм
 */
export const STATISTIC_URL = URL_PREFIX + "/statistic";

/**
 * Страница подробнее о боте
 */
export const BOT_INFO_URL = URL_PREFIX + "/info";

/**
 * Админский дашбоард \ меню
 */
export const ADMIN_DASHBOARD = URL_PREFIX + "/admin/dashboard";

/**
 * Адммин. Управление "прокладками"
 */
export const ADMIN_DATA_BROKER_URL = URL_PREFIX + "/admin/data-brokers";

/**
 * Админ. Управление JSON профилями
 */
export const ADMIN_JSON_PROFILES_URL = URL_PREFIX + "/admin/json-profiles";

/**
 * Админ. Отображение пользователями
 */
export const ADMIN_USERS_URL = URL_PREFIX + "/admin/users";

/**
 * Admin. Создание пользователя
 */
export const ADMIN_USER_CREATE_URL = URL_PREFIX + "/admin/user-create";

/**
 * Страница тегов
 */
export const TAGS_URL = URL_PREFIX + "/tags";

/**
 * Страница паролей
 */
export const PASSWORD_URL = URL_PREFIX + "/password";

/**
 * Страница корзины
 */
export const BIN_URL = URL_PREFIX + "/bin";

/**
 * Страница словарей приложения
 */
export const ADMIN_DICTIONARIES_URL = URL_PREFIX + "/admin/dictionaries";

/**
 * Страница кошельки
 */
export const WALLETS_URL = URL_PREFIX + "/wallets";

/**
 * Страница ошибок
 */
export const ADMIN_DATA_ERRORS_URL = URL_PREFIX + "/admin/data-errors";

/**
 * Страница логов приложения
 */
export const ADMIN_APP_LOGS_URL = URL_PREFIX + "/admin/logs";