import React from "react";
import {ArrowDownload20Regular} from "@fluentui/react-icons";
import {LoadingButton} from "../../ButtonWithLoadingState";
import {LogDownloadButtonProps} from "./LogDownloadButtonProp";
import {ApiError, LoadingState} from "../../../models";
import {
    Link,
    Text,
    Toast,
    ToastBody,
    Toaster,
    ToastFooter,
    ToastTitle,
    useId,
    useToastController
} from "@fluentui/react-components";
import {GetMessageByCode} from "../../../utils";
import {services} from "../../../services";
import {DOWNLOADS_URL} from "../../../shared/constUrl";

/**
 * Кнопка скачивания лога
 */
const LogDownloadButton: React.FC<LogDownloadButtonProps> = (props) => {
    const [loading, setLoading] = React.useState<LoadingState>("initial");
    const toasterId = useId('loadingButton');
    const {dispatchToast} = useToastController(toasterId);

    /**
     * Обработчик клика по кнопке
     */
    const onClickHandler = () => {
        (async () => {
            try {
                setLoading("loading");
                await services.fileDownloadService.requestLogFiles(props.logId)
                setLoading("loaded");
                dispatchToast(
                    <Toast style={{minHeight: 50 + "px", minWidth: 100 + "px"}}>
                        <ToastTitle>Downloading file</ToastTitle>
                        <ToastBody>
                            <Text>This may take a while</Text>
                            <Text>File requested and preparing for you</Text>
                        </ToastBody>
                        <ToastFooter>
                            <Link href={DOWNLOADS_URL}>Open download page</Link>
                        </ToastFooter>
                    </Toast>,
                    {intent: "success"}
                );
            } catch (error: any) {
                console.error(error);
                showErrorNotify(error)
            }
        })();
    };

    /**
     * Показ сообщения об ошибке
     * @param error Модель с ошибкой
     */
    const showErrorNotify = (error: ApiError) => {
        dispatchToast(
            <Toast style={{minHeight: 50 + "px", minWidth: 100 + "px"}}>
                <ToastTitle>Error!</ToastTitle>
                <ToastBody>{GetMessageByCode(error.code)}</ToastBody>
            </Toast>,
            {intent: "error"}
        );
    }

    return (<>
            <Toaster toasterId={toasterId}/>
            <LoadingButton aria-label="Download"
                           appearance={"primary"}
                           icon={<ArrowDownload20Regular/>}
                           state={loading}
                           enabled={true}
                           onClick={onClickHandler}/>
        </>
    );
}

/**
 * Имя отображаемое во время отладки
 */
LogDownloadButton.displayName = "LogDownloadButton";

export default LogDownloadButton;
