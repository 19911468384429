import React, {useCallback, useEffect, useState} from 'react';
import {Button, Input, Select, SelectOnChangeData, Subtitle2,} from '@fluentui/react-components';
import {useServices, useStore} from '../../hooks';
import UseStyle from './BuilderFormStyles';
import {BuilderOptionsDto, BuildRequestStatesDto} from '../../models/common';
import {ErrorCode} from '../../models/shared/ErrorCode';
import {useToast} from "../../utils/ToastProvider";
import {GetMessageByCode} from "../../utils";
import {observer} from "mobx-react-lite";

/**
 * Форма создания запроса на генерацию билда
 * @constructor
 */
const BuilderForm: React.FC = () => {
    const styles = UseStyle();
    const [buildTag, setBuildTag] = useState('');
    const services = useServices();
    const {showToast} = useToast();
    const store = useStore();

    /**
     * Получение списка рефералов
     */
    const fetchReferrals = useCallback(async () => {
        try {
            store.builderStore.partners = await services.builderService.getRefList();
        } catch (error: any) {
            const errorMessage =
                error.code === ErrorCode.CustomErrorMessage
                    ? error.message
                    : GetMessageByCode(error.code)

            showToast('Error!', errorMessage, "error");
        }
    }, [services.builderService]);

    /**
     * Получение списка партнёров
     */
    useEffect(() => {
        (async () => {
            await fetchReferrals();
        })();
    }, [services.builderService, fetchReferrals]);

    /**
     * отправка запроса на генерацию билда
     */
    const handleRequestNewBuild = async () => {
        try {
            const body: BuilderOptionsDto = {partnerId: store.builderStore.partnerId || '', buildTag: buildTag};
            await services.builderService.newRequest(body);
            store.builderStore.requestState.state = BuildRequestStatesDto.Waiting;
        } catch (error: any) {
            const errorMessage =
                error.code === ErrorCode.CustomErrorMessage
                    ? error.message
                    : GetMessageByCode(error.code)

            showToast('Error!', errorMessage, "error");
        }
    };

    /**
     * Обработчик выбора партнёра
     * @param _ev событие
     * @param data данные
     */
    const partnerChangeHandler = (_ev: any, data: SelectOnChangeData) => {
        store.builderStore.setPartnerId = data.value;
    }

    /**
     * Вывод списка партнёров при наличии
     */
    const showPartnerChooseInput = () => {
        if (store.builderStore.partners.length > 0) {
            return (
                <Select onChange={partnerChangeHandler}>
                    <option key={'no_choose'} value={''}/>
                    {store.builderStore.partners.map((referral) => (
                        <option key={referral.id} value={referral.id}>
                            {referral.userName}
                        </option>
                    ))}
                </Select>
            );
        }
        return;
    }

    return (
        <>
            <Subtitle2>Create New Build</Subtitle2>
            <form className={styles.form}>
                {showPartnerChooseInput()}
                <Input
                    placeholder="Enter build tag"
                    value={buildTag}
                    onChange={(event) => setBuildTag(event.target.value)}
                />
                <Button appearance={"primary"} onClick={handleRequestNewBuild}>Request new build</Button>
            </form>
        </>
    );
};

BuilderForm.displayName = "BuilderForm";

export default observer(BuilderForm);
