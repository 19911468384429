import {
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger
} from "@fluentui/react-components";
import React from "react";
import {ActionDialogProps} from "./ActionDialogProps";

/**
 * Компонент диалога для подтверждения действия
 * @constructor
 */
const ActionDialog: React.FC<ActionDialogProps> = (props) => {
    return (
        <Dialog open={props.isOpen}>
            <DialogTrigger disableButtonEnhancement>
                {React.isValidElement(props.trigger) ? (
                    props.trigger
                ) : (
                    <span>{props.trigger}</span>
                )}
            </DialogTrigger>
            <DialogSurface>
                <DialogBody>
                    <DialogTitle as={"h4"}>{props.title}</DialogTitle>
                    <DialogContent>
                        {props.content}
                    </DialogContent>
                    <DialogActions>
                        {props.actions}
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>);
}

/**
 * Имя отображаемое при отладке
 */
ActionDialog.displayName = "ActionDialog";

export default ActionDialog;