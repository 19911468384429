import React, {ReactElement} from "react";
import {LogSummaryProp} from "./LogSummaryProp";
import {Badge, mergeClasses, OverflowItem} from "@fluentui/react-components";
import {
    cookieColor,
    creditCardColor,
    extWalletsColor,
    messengerColor,
    passwordColor,
    softColor,
    walletsColor
} from "../../shared/constColors";
import useStyles from "../LogsList/LogsListStyle";
import {Cookies16Filled, KeyMultiple16Filled} from "@fluentui/react-icons";
import {SourceTypes} from "../../models/common/tags/";
import {v4 as uuidv4} from 'uuid';

/**
 * Компонент для отображения короткой информации о логе в виде бейджей
 * с возможностью перехода в некоторые разделы
 * @constructor
 */
const LogSummary: React.FC<LogSummaryProp> = (props) => {
    const styles = useStyles();
    const badgeSize = "medium";

    /**
     * Генерируем набор бейджей для отображения
     * @param props
     */
    const generateItems = (props: LogSummaryProp): ReactElement[] => {
        let items: React.ReactElement[] = [];

        if (props.isDuplicate) {
            items.push(<OverflowItem key={"dup"} id={"dup"}>
                <Badge size={badgeSize}
                       className={mergeClasses(styles.badgeDuplicate, styles.badgeSpacing)}>!DUP!</Badge>
            </OverflowItem>)
        }
        if (props.walletCount > 0) {
            items.push(<OverflowItem key={"WalletCount"} id={"WalletCount"}>
                <Badge size={badgeSize} className={styles.badgeSpacing}
                       style={{backgroundColor: walletsColor}}>Wallets: {props.walletCount}</Badge>
            </OverflowItem>)
        }
        if (props.extWalletCount > 0) {
            items.push(<OverflowItem key={"ExtWalletCount"} id={"ExtWalletCount"}>
                <Badge size={badgeSize} className={styles.badgeSpacing}
                       style={{background: extWalletsColor}}>eWallet: {props.extWalletCount}</Badge>
            </OverflowItem>)
        }
        if (props.softCount > 0) {
            items.push(<OverflowItem key={"softCount"} id={"softCount"}>
                <Badge size={badgeSize} className={styles.badgeSpacing}
                       style={{backgroundColor: softColor}}>Soft: {props.softCount}</Badge>
            </OverflowItem>)
        }
        if (props.creditCardsCount > 0) {
            items.push(<OverflowItem key={"creditCards"} id={"creditCards"}>
                <Badge size={badgeSize} className={styles.badgeSpacing}
                       style={{background: creditCardColor}}>CC: {props.creditCardsCount}</Badge>
            </OverflowItem>)
        }
        if (props.cookiesCount > 0) {
            items.push(<OverflowItem key={"cookieCount"} id={"cookieCount"}>
                <Badge size={badgeSize} className={styles.badgeSpacing}
                       style={{backgroundColor: cookieColor}}>Cookies: {props.cookiesCount}</Badge>
            </OverflowItem>)
        }
        if (props.passwordsCount > 0) {
            items.push(<OverflowItem key={"passwordsCount"} id={"passwordsCount"}>
                <Badge size={badgeSize} className={styles.badgeSpacing}
                       style={{backgroundColor: passwordColor}}>Pwd: {props.passwordsCount}</Badge>
            </OverflowItem>)
        }

        if (props.messengersCount > 0) {
            items.push(<OverflowItem key={"messengersCount"} id={"messengersCount"}>
                <Badge size={badgeSize} className={styles.badgeSpacing}
                       style={{backgroundColor: messengerColor}}>Messenger: {props.messengersCount}</Badge>
            </OverflowItem>)
        }

        if (props.tags && props.tags.length > 0) {
            props.tags.map((tag) => {
                const sourceIcons = chooseIconForTag(tag.sources);
                items.push(
                    <OverflowItem id={uuidv4()} key={uuidv4()}>
                        <Badge size={badgeSize} className={styles.badgeSpacing}
                               style={{backgroundColor: tag.color}}>{sourceIcons}{tag.name}</Badge>
                    </OverflowItem>
                )
                return;
            })
        }

        if (items.length > 0) {
            return items;
        }

        return [];
    }

    /**
     * Генерируется набор иконок для Badge в Summary
     * @param sourceType
     */
    const chooseIconForTag = (sourceType: SourceTypes[]): ReactElement[] => {
        let items: React.ReactElement[] = [];

        sourceType.map((elem) => {
            switch (elem) {
                case SourceTypes.Cookies:
                    items.push(<Cookies16Filled key={uuidv4()} alignmentBaseline={"auto"}/>)
                    break;
                case SourceTypes.Files:
                    break;
                case SourceTypes.Passwords:
                    items.push(<KeyMultiple16Filled key={uuidv4()}/>);
                    break;
            }
        })

        if (items.length > 0) {
            return items;
        }

        return [];
    };

    /**
     * Переменная хранящая все элементы Summary, если они сгенерированы
     */
    const itemsToDisplay = generateItems(props);

    if (itemsToDisplay && itemsToDisplay.length > 0) {
        return (
            <div className={styles.summaryBadges}>
                {itemsToDisplay.map<React.ReactNode>((el) => {
                    return (el)
                })}
            </div>
        );
    }

    // Если нет никаких данных для отображения в "Summary",
    // Возвращаем "пустой" компонент
    return <></>
}

/**
 * Имя отображаемое при отладке
 */
LogSummary.displayName = "LogSummary";

export default LogSummary;