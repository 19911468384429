import {LoadingButton} from "../ButtonWithLoadingState";
import {ArrowLeft20Regular, ArrowRight20Regular} from "@fluentui/react-icons";
import {Text} from "@fluentui/react-components";
import React from "react";
import {PaginationButtonProps} from "./PaginationButtonProps";

/**
 * Компонент для отображения кнопок пагинации
 * @constructor
 */
const PaginationButtons: React.FC<PaginationButtonProps> = (props) => {
    /**
     * Проверка возможности перехода назад
     */
    const btnPrevEnabled = () => {
        return props.currentPage === 1;
    }

    /**
     * Проверка возможности перехода вперёд
     */
    const btnNextEnabled = () => {
        return props.currentPage === props.totalPages;
    }

    return (<div style={{marginRight: "1em"}}>
        <LoadingButton icon={<ArrowLeft20Regular/>}
                       enabled={btnPrevEnabled()}
                       onClick={props.onPrevClick}/>

        <Text style={{
            marginLeft: '1em',
            marginRight: '1em',
        }}>{props.currentPage} of {props.totalPages}</Text>
        <LoadingButton icon={<ArrowRight20Regular/>}
                       enabled={btnNextEnabled()}
                       onClick={props.onNextClick}/>
        <Text style={{
            marginLeft: '1em',
            marginRight: '1em',
        }}>Total: {props.totalCount} </Text>
    </div>)
}

/**
 * Имя отображаемое при отладке
 */
PaginationButtons.displayName = "PaginationButtons";

export default PaginationButtons;