import React, {useCallback, useEffect, useState} from 'react';
import {
    Badge,
    Button,
    createTableColumn,
    DataGrid,
    DataGridBody,
    DataGridCell,
    DataGridHeader,
    DataGridHeaderCell,
    DataGridRow,
    Dialog,
    DialogActions,
    DialogSurface,
    DialogTitle,
    Input,
    Select,
    TableCellLayout,
    TableColumnDefinition,
} from "@fluentui/react-components";
import {useServices} from "../../hooks";
import {TagCreateVm, TagDTO, TagFilteringDto, TagType} from '../../models/common/tags';
import PaginationButtons from "../../components/PaginationButtons/PaginationButtons";

/**
 * форма тегов
*/
const TagsForm: React.FC = () => {
    const services = useServices(); 
    const [tags, setTags] = useState<TagDTO[]>([]);
    const [paging, setPaging] = useState({ pageNumber: 0, pageSize: 50, totalItems: 0, totalPages: 1 });
    const [filters, setFilters] = useState<TagFilteringDto>({ keyword: '', target: null, pageNumber: 0, pageSize: 50 });
    const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
    const [isImportDialogOpen, setIsImportDialogOpen] = useState(false);
    
    // Поля для формы создания тега
    const [newTagName, setNewTagName] = useState('');
    const [newTagKeyword, setNewTagKeyword] = useState('');
    const [newTagColor, setNewTagColor] = useState('#0078D4'); // Синий цвет по умолчанию
    const [searchInFiles, setSearchInFiles] = useState(false);

    // Поля для импорта файлов
    const [importFile, setImportFile] = useState<File | null>(null);
    const [importTarget, setImportTarget] = useState('1'); // "Browser" по умолчанию

    const fetchTags = useCallback(async () => {
        try {
            const result = await services.tagsService.getTagsFilterInfo(filters);
            console.log(result);
            console.log(filters);
            if (result) {
                setTags(result.data.tags);
                setPaging(result.data.paging);
            } else {
                // Обработка ошибки
            }
        } catch (error) {
            // Обработка ошибки
        }
    }, [filters, services.tagsService]);

    useEffect(() => {
        fetchTags();
    }, [fetchTags]);

    const handleCreateTag = async () => {
        const newTag: TagCreateVm = {
            name: newTagName,
            keyword: newTagKeyword,
            color: newTagColor,
            inFile: searchInFiles,
        };

        try {
            const result = await services.tagsService.createTags(newTag);
            if (result) {
                fetchTags(); 
                setIsCreateDialogOpen(false); // Закрываем диалог после успешного создания
            }
        } catch (error) {
            // Обработка ошибки
        }
    };

    const handleImportTags = async () => {
        if (importFile) {
            const formData = new FormData();
            formData.append('file', importFile);

            try {
                await services.tagsService.uploadTagsFromFile(formData, Number(importTarget));
                fetchTags();
                setIsImportDialogOpen(false); // Закрываем диалог после успешного импорта
            } catch (error) {
                // Обработка ошибки
            }
        }
    };

    const handleDeleteTag = async (id: number) => {
        try {
            await services.tagsService.deleteTag(String(id));
            fetchTags(); 
        } catch (error) {
            // Обработка ошибки
        }
    };


    const handlePageChange = (pageNumber: number) => {
        setFilters({ ...filters, pageNumber: pageNumber - 1 }); // Учитываем нулевую индексацию
    };

    const handlePageSizeChange = (pageSize: number) => {
        setFilters({ ...filters, pageSize });
    };

    // Опции для Select
    const selectOptions = [
        { value: 'All', text: 'All' },
        { value: '1', text: 'Browser' },
        { value: '2', text: 'File' },
    ];

    const columns: TableColumnDefinition<TagDTO>[] = [
        createTableColumn({
            columnId: "Tag",
            renderHeaderCell: () => "Tag",
            renderCell: (item) => (
                <Badge style={{ backgroundColor: item.color}}>{item.name}</Badge>
            ),
        }),
        createTableColumn({
            columnId: "target",
            renderHeaderCell: () => "target",
            renderCell: (item) => (
                <TableCellLayout>{TagType[item.autoTagType]}</TableCellLayout>
            ),
        }),
        createTableColumn({
            columnId: "key",
            renderHeaderCell: () => "key",
            renderCell: (item) => (
                <TableCellLayout>{item.key}</TableCellLayout>
            ),
        }),
        createTableColumn({
            columnId: "delete",
            renderHeaderCell: () => "",
            renderCell: (item) => (
                <Button appearance="subtle" onClick={() => handleDeleteTag(item.id)}>
                    Delete
                </Button>
            ),
        }),
    ];

    return (
        <div>
            <h2>Tag Management</h2>
            <div>
                <Select
                    value={filters.target !== null ? filters.target.toString() : 'All'}
                    onChange={(e, option) => {
                        const value = option.value as string;
                        const targetMap: { [key: string]: number | null } = {
                            'All': null,
                            '1': 1,
                            '2': 2,
                        };
                        setFilters({ ...filters, target: targetMap[value] ?? null });
                    }}
                >
                    {selectOptions.map(opt => (
                        <option key={opt.value} value={opt.value}>
                            {opt.text}
                        </option>
                    ))}
                </Select>
                <Select
                    value={String(filters.pageSize)}
                    onChange={(e, data) => handlePageSizeChange(parseInt(data.value || '0'))}
                >
                    <option>10</option>
                    <option>25</option>
                    <option>50</option>
                    <option>75</option>
                    <option>100</option>
                    <option>200</option>
                    <option>300</option>
                </Select>
                <Input
                    type="text"
                    placeholder="Type your keyword here"
                    value={filters.keyword}
                    onChange={(e, data) => setFilters({ ...filters, keyword: data.value || '' })}
                />
                <Button onClick={() => fetchTags()}>Search</Button>
            </div>
            <div>
                {/* Кнопки */}
                <Button appearance="primary" onClick={() => setIsCreateDialogOpen(true)}>
                    Create new
                </Button>
                <Button onClick={() => setIsImportDialogOpen(true)}>
                    Import from file
                </Button>
                <Button appearance="subtle" onClick={() => {/* Логика удаления тегов */}}>
                    Delete
                </Button>
            </div>
            {/* Диалог создания тега */}
            <Dialog open={isCreateDialogOpen} onOpenChange={(event, data) => setIsCreateDialogOpen(data.open)}>
                <DialogSurface>
                    <DialogTitle>Add new tag</DialogTitle>
                    <div>
                        <Input placeholder="Tag name" value={newTagName} onChange={(e, data) => setNewTagName(data.value)} />
                        <Input placeholder="Keyword" value={newTagKeyword} onChange={(e, data) => setNewTagKeyword(data.value)} />
                        <label>
                            <input type="checkbox" checked={searchInFiles} onChange={() => setSearchInFiles(!searchInFiles)} />
                            Search in files
                        </label>
                        <Input  value={newTagColor} onChange={(e) => setNewTagColor(e.target.value)} />
                    </div>
                    <DialogActions>
                        <Button appearance="primary" onClick={handleCreateTag}>Add</Button>
                        <Button onClick={() => setIsCreateDialogOpen(false)}>Cancel</Button>
                    </DialogActions>
                </DialogSurface>
            </Dialog>

            {/* Диалог импорта тегов */}
            <Dialog open={isImportDialogOpen} onOpenChange={(event, data) => setIsImportDialogOpen(data.open)}>
                <DialogSurface>
                    <DialogTitle>Import tag from file</DialogTitle>
                    <div>
                        <Select value={importTarget} onChange={(e, option) => setImportTarget(option.value)}>
                            <option value="1">Browser</option>
                            <option value="2">File</option>
                        </Select>
                        <input type="file" onChange={(e) => setImportFile(e.target.files?.[0] || null)} />
                    </div>
                    <DialogActions>
                        <Button appearance="primary" onClick={handleImportTags}>Upload</Button>
                        <Button onClick={() => setIsImportDialogOpen(false)}>Cancel</Button>
                    </DialogActions>
                </DialogSurface>
            </Dialog>
            <DataGrid items={tags} columns={columns}>
                <DataGridHeader>
                    <DataGridRow 
                    selectionCell={{
                            checkboxIndicator: {"aria-label": "Select all rows"},
                        }}>
                    {({renderHeaderCell}) => (
                            <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
                        )}
                    </DataGridRow>
                </DataGridHeader>
                <DataGridBody>
                {({item, rowId}) => (
                            <DataGridRow
                                key={rowId}
                                selectionCell={{
                                    checkboxIndicator: {"aria-label": "Select row"},
                                }}
                                
                            >
                                {({renderCell}) => (
                                    <DataGridCell as={"div"}>{renderCell(item)}</DataGridCell>
                                )}
                            </DataGridRow>
                        )}
                </DataGridBody>
            </DataGrid>
            <PaginationButtons
                currentPage={paging.pageNumber + 1 ?? 1}  // Отображаем текущую страницу начиная с 1
                totalPages={paging.totalPages ?? 1}
                totalCount={paging.totalItems ?? 1}
                onNextClick={() => handlePageChange(paging.pageNumber + 2)}  // Страница +1, так как нулевая индексация
                onPrevClick={() => handlePageChange(paging.pageNumber)}
            />
        </div>
    );
};

export default TagsForm;
