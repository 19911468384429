import "./App.css";
import {store, StoreContext} from "./store";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import {AuthorizationPage} from "./pages/SharedPages/Authorization";
import {FluentProvider, tokens, webLightTheme} from "@fluentui/react-components";
import CookiesPage from "./pages/UserPages/Cookies/CookiesPage";
import ProfilePage from "./pages/UserPages/Profile/ProfilePage";
import {ChangePasswordPage} from "./pages/UserPages/ChangePassword";
import {LogListPage} from "./pages/UserPages/LogListPage";
import React from "react";
import {
    ADMIN_APP_LOGS_URL,
    ADMIN_DASHBOARD,
    ADMIN_DATA_BROKER_URL,
    ADMIN_DATA_ERRORS_URL,
    ADMIN_DICTIONARIES_URL,
    ADMIN_JSON_PROFILES_URL,
    ADMIN_USER_CREATE_URL,
    ADMIN_USERS_URL,
    BIN_URL,
    BOT_INFO_URL,
    BUILDER_URL,
    CHANGE_PASSWORD_URL,
    COOKIE_LIST_URL,
    DOWNLOADS_URL,
    LOG_LIST_URL,
    PASSWORD_URL,
    PROFILE_URL,
    RESTORE_COOKIES_URL,
    STATISTIC_URL,
    TAGS_URL,
    TELEGRAM_NOTIFICATION_URL,
    WALLETS_URL
} from "./shared/constUrl";
import {observer} from "mobx-react-lite";
import {RestoreCookiesPage} from "./pages/UserPages/RestoreCookies";
import {TelegramNotificationsPage} from "./pages/UserPages/TelegramNotifications";
import {BuilderPage} from "./pages/UserPages/BuilderPage";
import {DownloadPage} from "./pages/UserPages/DownloadsPage";
import {StatisticPage} from "./pages/UserPages/StatisticPage";
import {BotInfoPage} from "./pages/UserPages/BotInfo";
import {Dashboard} from "./pages/AdminPages/Dashboard";
import DataBrokerPage from "./pages/AdminPages/DataBrokerPage/DataBrokerPage";
import JsonProfilesPage from "./pages/AdminPages/UserJsonConfigPage";
import UsersPage from "./pages/AdminPages/Users";
import CreateUserPage from "./pages/AdminPages/CreateUserPage/CreateUserPage";
import TagsPage from "./pages/UserPages/Tags/TagsPage";
import {PasswordPage} from "./pages/UserPages/password";
import {BinPage} from "./pages/UserPages/Bin";
import {ToastProvider} from "./utils/ToastProvider";
import {DictionariesPage} from "./pages/AdminPages/DictionariesPage";
import {WalletsPage} from "./pages/UserPages/WalletsPage/";
import {ErrorsPage} from "./pages/AdminPages/ErrorsPage/";
import {AppLogPage} from "./pages/AdminPages/AppLogsPage";

function App() {
    return (
        <FluentProvider theme={webLightTheme}>
            <ToastProvider>
                <div className="app" style={{backgroundColor: tokens.colorNeutralBackground4}}>
                    <BrowserRouter>
                        <StoreContext.Provider value={store}>
                            <Routes>
                                {store.userStore.isAuthorized ?
                                    (
                                        <>
                                            {store.userStore.isAdmin && (
                                                <>
                                                    <Route path={ADMIN_DASHBOARD} element={<Dashboard/>}/>
                                                    <Route path={ADMIN_DATA_BROKER_URL} element={<DataBrokerPage/>}/>
                                                    <Route path={ADMIN_JSON_PROFILES_URL}
                                                           element={<JsonProfilesPage/>}/>
                                                    <Route path={ADMIN_USERS_URL} element={<UsersPage/>}/>
                                                    <Route path={ADMIN_USER_CREATE_URL} element={<CreateUserPage/>}/>
                                                    <Route path={ADMIN_DICTIONARIES_URL} element={<DictionariesPage/>}/>
                                                    <Route path={ADMIN_DATA_ERRORS_URL} element={<ErrorsPage/>}/>
                                                    <Route path={ADMIN_APP_LOGS_URL} element={<AppLogPage/>}/>
                                                </>
                                            )}

                                            <Route path={CHANGE_PASSWORD_URL} element={<ChangePasswordPage/>}/>
                                            <Route path={TELEGRAM_NOTIFICATION_URL}
                                                   element={<TelegramNotificationsPage/>}/>
                                            <Route path={RESTORE_COOKIES_URL} element={<RestoreCookiesPage/>}/>
                                            <Route path={BUILDER_URL} element={<BuilderPage/>}/>
                                            <Route path={DOWNLOADS_URL} element={<DownloadPage/>}/>
                                            <Route path={STATISTIC_URL} element={<StatisticPage/>}/>
                                            <Route path={CHANGE_PASSWORD_URL} element={<ChangePasswordPage/>}/>
                                            <Route path={TELEGRAM_NOTIFICATION_URL}
                                                   element={<TelegramNotificationsPage/>}/>
                                            <Route path={RESTORE_COOKIES_URL} element={<RestoreCookiesPage/>}/>
                                            <Route path={BUILDER_URL} element={<BuilderPage/>}/>
                                            <Route path={DOWNLOADS_URL} element={<DownloadPage/>}/>
                                            <Route path={STATISTIC_URL} element={<StatisticPage/>}/>
                                            <Route path={LOG_LIST_URL} element={<LogListPage/>}/>
                                            <Route path={COOKIE_LIST_URL} element={<CookiesPage/>}/>
                                            <Route path={PROFILE_URL} element={<ProfilePage/>}/>
                                            <Route path={CHANGE_PASSWORD_URL} element={<ChangePasswordPage/>}/>
                                            <Route path={BOT_INFO_URL} element={<BotInfoPage/>}/>
                                            <Route path={TAGS_URL} element={<TagsPage/>}/>
                                            <Route path={PASSWORD_URL} element={<PasswordPage/>}/>
                                            <Route path={BIN_URL} element={<BinPage/>}/>
                                            <Route path={WALLETS_URL} element={<WalletsPage/>}></Route>
                                            <Route path="/" element={<Navigate to={LOG_LIST_URL} replace/>}/>
                                            <Route path="*" element={<Navigate to="/" replace/>}/>
                                        </>
                                    ) : (
                                        <>
                                            <Route path="/" element={<AuthorizationPage/>}/>
                                            <Route path="*" element={<Navigate to="/" replace/>}/>
                                        </>
                                    )
                                }
                            </Routes>
                        </StoreContext.Provider>
                    </BrowserRouter>
                </div>
            </ToastProvider>
        </FluentProvider>
    )
}

export default observer(App);