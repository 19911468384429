import {
    Button,
    Card,
    Table,
    TableBody,
    TableCell,
    TableCellLayout,
    TableHeader,
    TableHeaderCell,
    TableRow,
    tokens
} from "@fluentui/react-components";
import React, {useEffect, useState} from "react";
import {useServices, useStore} from "../../hooks";
import {observer} from "mobx-react-lite";
import {NoItems} from "../NoItems";
import Flag from "react-flagkit";
import {LogSummary} from "../LogSummary";
import {dateToMskString} from "../../utils/DateUtils";
import RestoreButton from "../RestoreButton/RestoreButton";
import ActionDialog from "../ActionDialog";
import {DeleteRegular} from "@fluentui/react-icons";

/**
 * Список удалённых логов
 * @constructor
 */
const BinItemsList: React.FC = () => {
    const service = useServices();
    const store = useStore();
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    useEffect(() => {
        try {
            service.binService.getDeleteList().then((data) => store.binLogsStore.updateData(data));
        } catch (error: any) {
            console.error(error);
        }
    }, [service.binService]);

    const onRestoreClick = async (logId: number) => {
        try {
            await service.binService.restore(logId);
            store.binLogsStore.removeItemById(logId);
        } catch (error: any) {
            console.error(error);
        }
    }

    /**
     * Удалить лог безвозвратно
     * @param logId ИД лога
     */
    const deletePermanently = async (logId: number) => {
        try {
            await service.binService.delete(logId);
            store.binLogsStore.removeItemById(logId);
        } catch (error: any) {
            console.error(error);
        }
    }

    return (
        <Card style={{width: '100%', padding: "0.3em"}}>
            <Table as={"table"} style={{width: '100%', padding: 'none'}}>
                <TableHeader as={"thead"}>
                    <TableRow as={"tr"} style={{
                        display: 'grid',
                        gridTemplateColumns: '60px 120px 100px 120px 100px 1fr 120px 180px 100px',
                        gap: '10x',
                        alignItems: 'center' // Вертикальная центровка для заголовков
                    }}>
                        {columns.map((column) => (
                            <TableHeaderCell key={column.columnKey} as={"th"} style={{
                                display: 'flex',
                                justifyContent: 'center', // Горизонтальная центровка
                                alignItems: 'center',     // Вертикальная центровка
                            }}>
                                {column.label}
                            </TableHeaderCell>
                        ))}
                    </TableRow>
                </TableHeader>
                <TableBody as={"tbody"}>
                    {(store.binLogsStore.paging.totalItems === 0)
                        ? <TableRow as={"tr"} style={{
                            display: 'grid',
                            gridTemplateColumns: '1fr',
                            height: 'auto',
                            alignItems: 'center' // Вертикальная центровка для пустых строк
                        }}>
                            <TableCell as={"td"} style={{
                                display: 'flex',
                                justifyContent: 'center', // Горизонтальная центровка
                                alignItems: 'center',
                                padding: '2em'// Вертикальная центровка
                            }}>
                                <NoItems/>
                            </TableCell>
                        </TableRow>
                        : store.binLogsStore.logs.map((item) => (
                            <TableRow as={"tr"} key={item.id} style={{
                                display: 'grid',
                                gridTemplateColumns: '60px 120px 100px 120px 100px 1fr 120px 180px 110px',
                                gap: '5px',
                                height: 'auto',
                                alignItems: 'center' // Центровка контента по вертикали
                            }}>
                                <TableCell as={"td"} style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                    <TableCellLayout>
                                        {item.id}
                                    </TableCellLayout>
                                </TableCell>

                                <TableCell as={"td"} style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                    <TableCellLayout>
                                        {item.ip}
                                    </TableCellLayout>
                                </TableCell>

                                <TableCell as={"td"} style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                    <TableCellLayout media={<Flag country={item.country} size={20}/>}>
                                        {item.country}
                                    </TableCellLayout>
                                </TableCell>

                                <TableCell as={"td"} style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                    <TableCellLayout>
                                        {item.city}
                                    </TableCellLayout>
                                </TableCell>

                                <TableCell as={"td"} style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                    <TableCellLayout>
                                        {item.zipCode}
                                    </TableCellLayout>
                                </TableCell>

                                <TableCell as={"td"} style={{
                                    display: 'block',
                                    whiteSpace: 'normal',
                                    wordWrap: 'break-word',
                                    height: 'auto',
                                    overflow: 'visible',
                                }}>
                                    <TableCellLayout>
                                        <LogSummary walletCount={item.walletCount}
                                                    isDuplicate={item.isDuplicate}
                                                    extWalletCount={item.extWalletCount}
                                                    cookiesCount={item.cookiesCount}
                                                    passwordsCount={item.passwordsCount}
                                                    creditCardsCount={item.creditCardsCount}
                                                    pmExtCount={item.pmExtCount} messengersCount={item.messengersCount}
                                                    softCount={item.softCount} tags={item.tags}/>
                                    </TableCellLayout>
                                </TableCell>

                                <TableCell as={'td'} style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                    <TableCellLayout>
                                        {item.builderTag}
                                    </TableCellLayout>
                                </TableCell>

                                <TableCell as={"td"} style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                    <TableCellLayout>
                                        {dateToMskString(new Date(item.createdOnUtc))}
                                    </TableCellLayout>
                                </TableCell>

                                <TableCell as={"td"} style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}>
                                    <TableCellLayout>
                                        <div style={{
                                            display: "flex",
                                            justifyContent: "start",
                                            gap: '1px',
                                            alignItems: "center",
                                        }}>
                                            <RestoreButton onClick={() => {
                                                onRestoreClick(item.id);
                                            }}/>
                                            <ActionDialog
                                                title={"Warning: This action cannot be undone!"}
                                                isOpen={isDialogOpen}
                                                actions={
                                                    <>
                                                        <Button appearance="secondary"
                                                                onClick={() => setIsDialogOpen(false)}>
                                                            Cancel
                                                        </Button>
                                                        <Button appearance={"primary"}
                                                                style={{background: tokens.colorPaletteDarkOrangeForeground1}}
                                                                onClick={() => {
                                                                    deletePermanently(item.id)
                                                                }}>
                                                            Delete
                                                        </Button>
                                                    </>}
                                                trigger={
                                                    <Button
                                                        appearance={"primary"}
                                                        icon={<DeleteRegular/>}
                                                        style={{
                                                            background: tokens.colorPaletteDarkOrangeForeground1
                                                        }}
                                                        onClick={() => setIsDialogOpen(true)}/>}
                                                content={"Are you sure you want to delete this item?"}
                                            />
                                        </div>
                                    </TableCellLayout>
                                </TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
        </Card>
    );
}

const columns = [
    {columnKey: "id", label: "Id"},
    {columnKey: "ip", label: "IP"},
    {columnKey: "country", label: "Country"},
    {columnKey: "city", label: "City"},
    {columnKey: "zipCode", label: "ZipCode"},
    {columnKey: "summary", label: "Summary"},
    {columnKey: "buildTag", label: "Build Tag"},
    {columnKey: "created", label: "Created"},
    {columnKey: "actions", label: "Actions"},
];

/**
 * Имя отображаемое при отладке
 */
BinItemsList.displayName = "BinItemsList";

export default observer(BinItemsList);