import {AxiosService} from "./axios";
import {DictDto} from "../models/admin/adminDashboard/dictDto";

export class DictService {

    /**
     * Экземпляр axios сервиса
     * @private
     */
    private axios: AxiosService;

    /**
     * Адрес для получения данных по словарям
     * @private
     */
    private baseUrl: string = '/admin/dictionaries';

    constructor() {
        this.axios = new AxiosService();
    }

    async getDict(): Promise<DictDto[]> {
        try {
            const response = await this.axios.get<DictDto[]>(this.baseUrl)
            return response.data;
        } catch (error: any) {
            return Promise.reject(error);
        }
    }
}