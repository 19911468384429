import React, {useEffect, useState} from 'react';
import {
    Button,
    Card,
    Input,
    Select,
    Spinner,
    Table,
    TableBody,
    TableCell,
    TableHeader,
    TableRow,
    Text
} from '@fluentui/react-components';
import {useServices} from '../../hooks';
import UseStyle from './StatisticSharedFormStyles';
import {CreateLinkDto, SharedLinksDto} from '../../models/common';

/**
 * порма создания публичной ссылки на статистику
 */
const StatisticSharedForm: React.FC = () => {
    const [sharedLinks, setSharedLinks] = useState<SharedLinksDto[]>([]);
    const [loading, setLoading] = useState(false);
    const [password, setPassword] = useState('');
    const [params, setParams] = useState('');
    const services = useServices();
    const styles = UseStyle();

    /**
     * получение списка уже существующих ссылок с апи
     */
    const fetchSharedLinks = async () => {
        setLoading(true);
        try {
            const result = await services.statisticService.getStatisticLink();
            if (result.error) throw new Error(result.error.message);
            setSharedLinks(result.data.sharedLinks);
        } catch (error: any) {
            console.error('Error fetching shared links:', error.message);
        } finally {
            setLoading(false);
        }
    };

    /**
     * создание ссылки
     */
    const createSharedLink = async () => {
        setLoading(true);
        const body: CreateLinkDto = {
            password,
            params
        };
        try {
            const result = await services.statisticService.createStatisticLink(body);
            if (result.error) throw new Error(result.error.message);
            await fetchSharedLinks();
        } catch (error: any) {
            console.error('Error creating shared link:', error.message);
        } finally {
            setLoading(false);
        }
    };

    /**
     * удаление ссылки
     */
    const deleteSharedLink = async (id: string) => {
        setLoading(true);
        try {
            console.log(id);
            const result = await services.statisticService.deleteStatisticLink(id);
            if (result.error) throw new Error(result.error.message);
            await fetchSharedLinks();
        } catch (error: any) {
            console.error('Error deleting shared link:', error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchSharedLinks();
    }, []);

    return (
        <Card className={styles.cardContainer}>
            <div className={styles.form}>
                <Text size={500}>Create New Statistic Link</Text>
                <Input
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                />
                <Select>
                </Select>
                <Button onClick={createSharedLink}>Create Link</Button>
            </div>
            {loading ? (
                <Spinner/>
            ) : (
                <div className={styles.linksContainer}>
                    <Text size={500}>Shared Links</Text>
                    <Table>
                        <TableHeader>
                            <TableRow>
                                <TableCell>Link ID</TableCell>
                                <TableCell>Last Use</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {sharedLinks.length > 0 ? (
                                sharedLinks.map((link) => (
                                    <TableRow key={link.id}>
                                        <TableCell>{link.id}</TableCell>
                                        <TableCell>{new Date(link.lastUse).toLocaleString()}</TableCell>
                                        <TableCell>
                                            <Button onClick={() => deleteSharedLink(link.id)}>Delete</Button>
                                        </TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={3}>No Links Found</TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </div>
            )}
        </Card>
    );
};

export default StatisticSharedForm;
