import {AuthService} from "./authService";
import {TokenService} from "./tokenService";
import {SubscriptionService} from "./subscriptionService";
import {SessionsService} from "./sessionsService";
import {AccountService} from "./accountService";
import {LogsService} from "./logsService";
import {TelegramBotService} from "./telegramBotService";
import {CookiesRestoreService} from "./сookiesRestoreService";
import {FileDownloadService} from "./fileDownloadService";
import {LogNoteService} from "./logNoteService";
import {BuilderService} from "./builderService";
import {DownloadsService} from "./downloadsService";
import {StatisticService} from "./statisticService";
import {BotInfoService} from "./botinfoService";
import {AdminDashboardService} from "./adminDashboardService";
import {AdminDataBrokerService} from "./adminDataBrokerService";
import {AdminJsonProfilesService} from "./adminJsonProfilesService";
import {AdminUserService} from "./adminUserService";
import {CookiesService} from "./cookiesService";
import {TagsService} from "./tagsService";
import {PasswordService} from "./passwordService";
import {BinService} from "./binService";
import {DictService} from "./dictService";
import {WalletsService} from "./walletsService";
import {AdminDataErrorsService} from "./adminDataErrorsService";
import {AppLogService} from "./adminAppLogService";

/**
 * Корневой сервис
 */
export class RootService {

    /**
     * Сервис авторизации.
     */
    public readonly authorizationService: AuthService;

    /**
     * Сервис токенов
     */
    public readonly tokenService: TokenService;

    /**
     * Сервис работы с подписками
     */
    public readonly subscriptionService: SubscriptionService;

    /**
     * Сервис для работы с сессиями пользователя
     */
    public readonly sessionsService: SessionsService;

    /**
     * Сервис работы с аккаунтом
     */
    public readonly accountService: AccountService;

    /**
     * Сервис работы с логами
     */
    public readonly logsService: LogsService;

    /**
     * Сервис работы с телеграм
     */
    public readonly telegramBotService: TelegramBotService;
    /**
     * Сервис работы с восстановлением кук
     */
    public readonly cookiesRestoreService: CookiesRestoreService;

    /**
     * Сервис билдера
     */
    public readonly builderService: BuilderService;

    /**
     * Сервис для работы с запросами файлов для скачивания
     */
    public readonly fileDownloadService: FileDownloadService;

    /**
     * Сервис работы с заметками к логам
     */
    public readonly logNoteService: LogNoteService;

    /**
     * Сервис работы со страницей загрузок
     */
    public readonly downloadsService: DownloadsService;

    /**
     * Сервис работы со страницей загрузок
     */
    public readonly statisticService: StatisticService;

    /**
     * Сервис работы со страницей подробнее о боте
     */
    public readonly botInfoService: BotInfoService;

    /**
     * Сервис для работы со страницей администратора
     */
    public readonly adminDashboardService: AdminDashboardService;

    /**
     * Сервис по работе с посредниками данных (прокладками)
     */
    public readonly adminDataBrokerService: AdminDataBrokerService;

    /**
     * Сервис работы с JSON профилями пользователей
     */
    public readonly adminJsonProfilesService: AdminJsonProfilesService;

    /**
     * Сервис для работы с пользователями
     */
    public readonly adminUserService: AdminUserService;

    /**
     * Сервис для работы с тегами
     */
    public readonly tagsService: TagsService;

    /**
     * Сервис для работы с паролями
     */
    public readonly passwordService: PasswordService;

    /**
     * Сервис для работы с Cookies
     */
    public readonly cookiesService: CookiesService;

    /**
     * Сервис для работы с корзиной
     */
    public readonly binService: BinService;

    /**
     * Сервис для работы с кошельками
     */
    public readonly walletsService: WalletsService;

    /**
     * Сервис для работы со словарями приложения
     */
    public readonly dictService: DictService;

    /**
     * Сервис для работы с ошибками данных
     */
    public readonly adminDataErrorService: AdminDataErrorsService;

    /**
     * Сервис для работы с логами приложения
     */
    public readonly adminAppLogService: AppLogService;

    /**
     * Конструктор.
     */
    constructor() {
        this.authorizationService = new AuthService(this);
        this.tokenService = new TokenService();
        this.subscriptionService = new SubscriptionService();
        this.sessionsService = new SessionsService();
        this.accountService = new AccountService();
        this.logsService = new LogsService();
        this.telegramBotService = new TelegramBotService();
        this.cookiesRestoreService = new CookiesRestoreService();
        this.fileDownloadService = new FileDownloadService();
        this.logNoteService = new LogNoteService();
        this.builderService = new BuilderService();
        this.downloadsService = new DownloadsService();
        this.statisticService = new StatisticService();
        this.botInfoService = new BotInfoService();
        this.adminDashboardService = new AdminDashboardService();
        this.adminDataBrokerService = new AdminDataBrokerService();
        this.adminJsonProfilesService = new AdminJsonProfilesService();
        this.adminUserService = new AdminUserService();
        this.cookiesService = new CookiesService();
        this.tagsService = new TagsService();
        this.passwordService = new PasswordService();
        this.binService = new BinService();
        this.walletsService = new WalletsService();
        this.dictService = new DictService();
        this.adminDataErrorService = new AdminDataErrorsService();
        this.adminAppLogService = new AppLogService();
    }
}