import React, {useEffect, useRef, useState} from "react";
import {Card} from "@fluentui/react-components";

/**
 * Компонент с отслеживанием ширины родительского элемента с помощью ResizeObserver
 */
const ToolsContainer: React.FC<React.PropsWithChildren<{}>> = ({children}) => {
    const [isSticky, setIsSticky] = useState(false);
    const [containerWidth, setContainerWidth] = useState<string | number>('100%');
    const containerRef = useRef<HTMLDivElement | null>(null); // Реф на сам ToolsContainer

    useEffect(() => {
        const resizeObserver = new ResizeObserver((entries) => {
            for (let entry of entries) {
                if (entry.target.parentElement) {
                    // Устанавливаем ширину родительского контейнера (Layout)
                    setContainerWidth(entry.target.parentElement.clientWidth);
                }
            }
        });

        if (containerRef.current) {
            resizeObserver.observe(containerRef.current); // Подписка на изменения размеров родителя
        }

        const handleScroll = () => {
            if (window.scrollY > 15) {
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
            resizeObserver.disconnect(); // Отключаем наблюдатель при размонтировании компонента
        };
    }, []);

    return (
        <Card
            ref={containerRef}
            style={{
                zIndex: 1000,
                top: isSticky ? '43px' : 'auto',
                width: isSticky ? containerWidth : "100%", // Применение ширины родительского элемента
                transition: "box-shadow 0.3s ease",
                marginBottom: "0.5em",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                position: isSticky ? 'fixed' : 'relative', // Фиксация при прокрутке
                backgroundColor: "white",
            }}
        >
            {children}
        </Card>
    );
};

ToolsContainer.displayName = "ToolsContainer";

export default ToolsContainer;
