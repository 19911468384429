import {
    Card,
    Table,
    TableBody,
    TableCell,
    TableCellLayout,
    TableHeader,
    TableHeaderCell,
    TableRow,
    TableSelectionCell
} from "@fluentui/react-components";
import {observer} from "mobx-react-lite";
import useStyles from "./LogsListStyle";
import React, {useCallback, useEffect, useRef, useState} from "react";
import Log from "../../models/common/logs/Log";
import {useServices, useStore} from "../../hooks";
import {GetMessageByCode} from "../../utils";
import {ApiError} from "../../models";
import NoItems from "../NoItems/NoItems";
import Flag from "react-flagkit";
import {LogSummary} from "../LogSummary";
import {dateToMskString} from "../../utils/DateUtils";
import {LogDownloadButton} from "./LogDownloadButton";
import {LogOpenDetailsButton} from "../LogOpenDetailsButton";
import {LogNoteButton} from "./LogNoteButton";
import {LogDeleteButton} from "./LogDeleteButton";
import {useToast} from "../../utils/ToastProvider";

/**
 * Компонент отображения списка логов
 * @constructor
 */
const LogsList: React.FC = () => {
    const {showToast} = useToast();
    const styles = useStyles();
    const services = useServices();
    const store = useStore();
    const selectAllRef = useRef<HTMLInputElement>(null);

    /**
     * Используется для предотвращения срабатывания эффектов которые зависят от параметров
     */
    const [initialLoad, setInitialLoad] = useState(true);

    useEffect(() => {
        if (selectAllRef.current) {
            selectAllRef.current.indeterminate =
                store.logsStore.selectedLogs.length > 0 &&
                store.logsStore.selectedLogs.length < store.logsStore.logs.length;
        }
    }, [store.logsStore.selectedLogs.length, store.logsStore.logs.length]);

    /**
     * Показ сообщения об ошибке
     * @param error Модель с ошибкой
     */
    const showErrorNotify = useCallback((error: ApiError) => {
        showToast('Error!', GetMessageByCode(error.code), "error");
    }, [showToast]);

    /**
     * Загрузка опций для фильтрации
     */
    useEffect(() => {
        (async () => {
            try {
                store.logsStore.options = await services.logsService.getOptions();
            } catch (error: any) {
                showErrorNotify(error);
            } finally {
                store.loaderStore.setLoading(false);
            }
        })();
    }, [store.logsStore, services.logsService, store.loaderStore, showErrorNotify]);

    /**
     * Эффект для первоначальной загрузки данных
     */
    useEffect(() => {
        (async () => {
            try {
                store.loaderStore.setLoading(true);
                const response = await services.logsService.getLogsList(store.logsStore.params);
                store.logsStore.updateData(response);
            } catch (error: any) {
                showErrorNotify(error);
            } finally {
                store.loaderStore.setLoading(false);
                setInitialLoad(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Эффект для обработки изменения номера страницы или размера
     */
    useEffect(() => {
        if (initialLoad) return;
        (async () => {
            try {
                store.loaderStore.setLoading(true);
                const response = await services.logsService.getLogsList(store.logsStore.params)
                store.logsStore.updateData(response);
            } catch (error: any) {
                showErrorNotify(error);
            } finally {
                store.loaderStore.setLoading(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.logsStore.params.pageSize,
        store.logsStore.params.pageNumber,
        showErrorNotify]);

    /**
     * Выбор стиля в зависимости от характеристик лога
     * Например: дубликат, скачан или нет
     * @param item
     */
    const chooseStyle = (item: Log) => {
        if (item.isDuplicate && item.isDownloaded) {
            return styles.downloadedDuplicate;
        }

        if (item.isDownloaded && !item.isDuplicate) {
            return styles.downloaded;
        }

        return styles.normalRow;
    }

    /**
     * Обработчик для выбора/снятия выделения строки
     * @param item Log
     */
    const handleRowSelect = (item: Log) => {
        // Получаем текущие выбранные логи
        const prevSelectedIds = store.logsStore.selectedLogs;

        // Обновляем список выбранных логов
        store.logsStore.selectedLogs = prevSelectedIds.includes(item.id)
            ? prevSelectedIds.filter((id: number) => id !== item.id)
            : [...prevSelectedIds, item.id];
    };

    /**
     * Обработчик для выбора/снятия выделения всех строк
     */
    const handleSelectAll = () => {
        if (store.logsStore.selectedLogs.length === store.logsStore.logs.length) {
            // Снять выделение со всех
            store.logsStore.selectedLogs = [];
        } else {
            // Выделить все
            store.logsStore.selectedLogs = store.logsStore.logs.map((log) => log.id);
        }
    };

    return (
        <Card style={{width: '100%', padding: "0.3em"}}>
            <Table as={"div"} size={"small"}>
                <TableHeader as={"thead"}>
                    <TableRow as={"tr"} style={{
                        display: 'grid',
                        gridTemplateColumns: columnTemplate,
                        alignItems: 'center' // Вертикальная центровка для заголовков
                    }}>
                        <TableSelectionCell
                            checkboxIndicator={{"aria-label": "Select all rows"}}
                            checked={store.logsStore.selectedLogs.length === store.logsStore.logs.length} // Полный выбор
                            onClick={handleSelectAll} // Обработчик для выбора всех строк
                        />

                        {columns.map((column) => (
                            <TableHeaderCell key={column.columnKey} as={"div"} style={{
                                justifyContent: 'center', // Горизонтальная центровка
                                alignItems: 'center',     // Вертикальная центровка
                            }}>{column.label}</TableHeaderCell>
                        ))}
                    </TableRow>
                </TableHeader>
                <TableBody as={"tbody"}>
                    {(store.logsStore.paging.totalItems === 0)
                        ? <TableRow as={"div"} style={{
                            display: 'grid',
                            gridTemplateColumns: '1fr',
                            height: 'auto',
                            alignItems: 'center' // Вертикальная центровка для пустых строк
                        }}>
                            <TableCell as={"div"} style={{
                                display: 'flex',
                                justifyContent: 'center', // Горизонтальная центровка
                                alignItems: 'center',
                                padding: '2em'// Вертикальная центровка
                            }}>
                                <NoItems/>
                            </TableCell>
                        </TableRow>
                        : store.logsStore.logs.map((item) => (
                            <TableRow as={"div"} className={chooseStyle(item)} key={item.id} style={{
                                display: 'grid',
                                gridTemplateColumns: columnTemplate,
                                height: 'auto',
                                alignItems: 'center' // Центровка контента по вертикали
                            }}>
                                <TableSelectionCell
                                    checkboxIndicator={{"aria-label": "Select row"}}
                                    checked={store.logsStore.selectedLogs.includes(item.id)} // Проверка, выбран ли этот лог
                                    onClick={() => handleRowSelect(item)} // Обработчик выбора строки
                                />
                                <TableCell as={"div"} style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                    <TableCellLayout>
                                        {item.id}
                                    </TableCellLayout>
                                </TableCell>

                                <TableCell as={"div"} style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                    <TableCellLayout>
                                        {item.ip}
                                    </TableCellLayout>
                                </TableCell>

                                <TableCell as={"div"} style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                    <TableCellLayout media={<Flag country={item.country} size={20}/>}>
                                        {item.country}
                                    </TableCellLayout>
                                </TableCell>

                                <TableCell as={"div"} style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                    <TableCellLayout>
                                        {item.city}
                                    </TableCellLayout>
                                </TableCell>

                                <TableCell as={"div"} style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                    <TableCellLayout>
                                        {item.zipCode}
                                    </TableCellLayout>
                                </TableCell>

                                <TableCell as={"div"} style={{
                                    display: 'block',
                                    whiteSpace: 'normal',
                                    wordWrap: 'break-word',
                                    height: 'auto',
                                    overflow: 'visible',
                                }}>
                                    <TableCellLayout>
                                        <LogSummary walletCount={item.walletCount}
                                                    isDuplicate={item.isDuplicate}
                                                    extWalletCount={item.extWalletCount}
                                                    cookiesCount={item.cookiesCount}
                                                    passwordsCount={item.passwordsCount}
                                                    creditCardsCount={item.creditCardsCount}
                                                    pmExtCount={item.pmExtCount} messengersCount={item.messengersCount}
                                                    softCount={item.softCount} tags={item.tags}/>
                                    </TableCellLayout>
                                </TableCell>

                                <TableCell as={'td'} style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                    <TableCellLayout>
                                        {item.builderTag}
                                    </TableCellLayout>
                                </TableCell>

                                <TableCell as={"div"} style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                    <TableCellLayout>
                                        {dateToMskString(new Date(item.createdOnUtc))}
                                    </TableCellLayout>
                                </TableCell>

                                <TableCell as={"div"} style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}>
                                    <TableCellLayout>
                                        <div style={{
                                            display: "flex",
                                            justifyContent: "start",
                                            gap: '1px',
                                            alignItems: "center",
                                        }}>
                                            <LogDownloadButton logId={item.id}/>
                                            <LogOpenDetailsButton logId={item.id}/>
                                            <LogNoteButton logId={item.id} noteId={item.noteId}
                                                           noteValue={item.noteText}/>
                                            <LogDeleteButton logId={item.id}/>
                                        </div>
                                    </TableCellLayout>
                                </TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
        </Card>
    );
};

const columnTemplate = '3em 10em 106px 80px 100px 100px 1fr 120px 140px 135px';

const columns = [
    {columnKey: "check", label: "", size: ""},
    {columnKey: "id", label: "Id"},
    {columnKey: "ip", label: "IP"},
    {columnKey: "country", label: "Country"},
    {columnKey: "city", label: "City"},
    {columnKey: "zipCode", label: "ZipCode"},
    {columnKey: "summary", label: "Summary"},
    {columnKey: "buildTag", label: "Build Tag"},
    {columnKey: "created", label: "Created"},
    {columnKey: "actions", label: "Actions"},
];

/**
 * Имя отображаемое при отладке
 */
LogsList.displayName = 'LogsList';

export default observer(LogsList);
