import React from "react";
import {Layout} from "../../../containers/Layout";
import useStyle from "./TelegramNotificationsPageStyle";
import {TelegramNotificationsForm, TelegramNotificationsUsersForm} from "../../../forms/TelegramNotificationsForm";

/**
 * Страница управление оповещения через Телеграм
 * @constructor
 */
const TelegramNotificationsPage: React.FC = () => {
    const styles = useStyle();
    return (<Layout pageTittle={"Telegram notification"} anotherBackground={true}>
        <div className={styles.TelegramNotificationsForm}>
            <TelegramNotificationsForm></TelegramNotificationsForm>
            <TelegramNotificationsUsersForm></TelegramNotificationsUsersForm>
        </div>
    </Layout>);
}

/**
 * Имя компонента при отладке
 */
TelegramNotificationsPage.displayName = "TelegramNotificationsPage";

export default TelegramNotificationsPage;