import React, {useCallback, useEffect, useState} from 'react';
import {Badge, Button, Card, Table, TableBody, TableCell, TableHeader, TableRow} from '@fluentui/react-components';
import {useServices} from '../../hooks';
import {FileRequestsDto, FileRequestStateDto, RequestFileTypeDto} from '../../models/common';
import UseStyle from './DownloadFormStyles';
import {dateToMskString} from "../../utils/DateUtils";

/**
 * форма списка загрузок
 */
const DownloadForm: React.FC = () => {
    const [fileRequests, setFileRequests] = useState<FileRequestsDto[]>([]);
    const services = useServices();
    const styles = UseStyle();

    /**
     * получение списка файлов с апи
     */
    const fetchFileRequests = useCallback(async () => {
        try {
            const result = await services.downloadsService.getLoadsList();
            setFileRequests(result.data.fileRequests || []);
        } catch (error: any) {
            console.error('Error fetching file requests:', error.message);
        }
    }, [services.downloadsService]);

    useEffect(() => {
        fetchFileRequests();
    }, [fetchFileRequests]);

    /**
     * логика скачивания файла
     */
    const handleDownload = (fileId: string) => {
        services.fileDownloadService.downloadFile("/downloads/get/" + fileId);
    };

    /**
     * Логика удаления файла
     */
    const handleDelete = async (requestId: string) => {
        await services.downloadsService.deleteFile(requestId);
    };

    return (
        <Card className={styles.cardContainer}>
            <Table>
                <TableHeader>
                    <TableRow>
                        <TableCell>Id</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell>Request At</TableCell>
                        <TableCell>Actions</TableCell>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {fileRequests.map((fileRequest) => (
                        <TableRow key={fileRequest.requestId}>
                            <TableCell>{fileRequest.requestId}</TableCell>
                            <TableCell><Badge>{FileRequestStateDto[fileRequest.requestState]}</Badge></TableCell>
                            <TableCell>{RequestFileTypeDto[fileRequest.fileType]}</TableCell>
                            <TableCell>{dateToMskString(new Date(fileRequest.createdAt))}</TableCell>
                            <TableCell>
                                <Button
                                    onClick={() => handleDownload(fileRequest.requestId)}
                                    disabled={fileRequest.requestState !== FileRequestStateDto.Done}
                                >
                                    Download
                                </Button>
                                <Button onClick={() => handleDelete(fileRequest.requestId)}>
                                    Delete
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Card>
    );
};

export default DownloadForm;
