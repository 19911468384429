import React from "react";
import {Layout} from "../../../containers/Layout";
import useStyle from "./BuilderPageStyle";
import {BuilderManaging} from "../../../components/BuilderManaging";
import {BuilderHistory} from "../../../components/BuilderHistory";

/**
 * Компонент-страница "Билдер"
 * @constructor
 */
const BuilderPage: React.FC = () => {
    const styles = useStyle();
    return (<Layout pageTittle={"Builder"} anotherBackground={true}>
        <div className={styles.BuilderForm}>
            <BuilderManaging/>
            <BuilderHistory/>
        </div>
    </Layout>);
}

/**
 * Имя отображаемое при отладке
 */
BuilderPage.displayName = "BuilderPage";
export default BuilderPage;