import React from "react";
import {Layout} from "../../../containers/Layout";
import {ProfileSubscribeInfo} from "../../../components/SubscribeInfo";
import ProfileSecurityWidget from "../../../components/UserSessions/ProfileSecurityWidget";
import useStyles from './ProfilePageStyle';

const ProfilePage: React.FC = () => {
    const styles = useStyles();
    return (<Layout pageTittle={"Profile"} anotherBackground={true}>
        <div className={styles.profileCards}>
            <ProfileSubscribeInfo></ProfileSubscribeInfo>
            <ProfileSecurityWidget></ProfileSecurityWidget>
        </div>
    </Layout>);
}

/**
 * Название компонента, используемое при отладке.
 */
ProfilePage.displayName = "ProfilePage";

export default ProfilePage;