import {AxiosService} from "./axios";
import {DataBrokerDto} from "../models/admin/dataBroker";

/**
 * Сервис для работы с посредниками данных ("прокладками")
 */
export class AdminDataBrokerService {

    /**
     * Экземпляр axios сервиса
     * @private
     */
    private axios: AxiosService;

    /**
     * Конструктор сервиса
     */
    constructor() {
        this.axios = new AxiosService();
    }

    /**
     * Получить счётчики для административных функция
     */
    async getList(): Promise<DataBrokerDto[]> {
        try {
            const response = await this.axios.get("/admin/data-brokers/list");
            return Promise.resolve(response.data as DataBrokerDto[]);
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    /**
     * Добавление новой прокладки
     * @param dataBrokerUrl
     */
    async add(dataBrokerUrl: string): Promise<void> {
        try {
            await this.axios.post("/admin/data-brokers/add-new", dataBrokerUrl);
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    /**
     * Удалить прокладку из списка
     * @param id Идентификатор прокладки
     */
    async delete(id: number): Promise<void> {
        try {
            await this.axios.delete("/admin/data-brokers/delete?id=" + id);
        } catch (error: any) {
            return Promise.reject(error);
        }
    }
}