import React, {useCallback, useEffect, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import {
    Button,
    createTableColumn,
    DataGrid,
    DataGridBody,
    DataGridCell,
    DataGridHeader,
    DataGridHeaderCell,
    DataGridRow,
    Input,
    Select,
    TableCellLayout,
    TableColumnDefinition,
} from "@fluentui/react-components";
import {useServices} from "../../hooks";
import PaginationButtons from "../../components/PaginationButtons/PaginationButtons";
import {BPasswords, PasswordDto} from '../../models/common';

/**
 * форма паролей
 */
const PasswordForm: React.FC = () => {
    const [searchParams] = useSearchParams();
    const botId = searchParams.get('botid');
    const services = useServices();
    const [password, setPassword] = useState<BPasswords[]>([]);
    const [paging, setPaging] = useState({pageNumber: 0, pageSize: 50, totalItems: 0, totalPages: 1});
    const [filters, setFilters] = useState<PasswordDto>({
        botId: Number(botId),
        keyword: '',
        browserName: '',
        pageNumber: 0,
        pageSize: 50
    });

    const fetchTags = useCallback(async () => {
        try {
            const result = await services.passwordService.getPasswordFilters(filters);
            console.log(filters);
            if (result) {
                setPassword(result.data.passwords);
                setPaging(result.data.paging);
            } else {
                // Обработка ошибки
            }
        } catch (error) {
            // Обработка ошибки
        }
    }, [filters, services.passwordService]);

    useEffect(() => {
        fetchTags();
    }, [fetchTags]);

    const handleDeletePass = async (id: number) => {
        try {
            await services.passwordService.deletePassword(id);
            fetchTags();
        } catch (error) {
            // Обработка ошибки
        }
    };

    const handlePageChange = (pageNumber: number) => {
        setFilters({...filters, pageNumber: pageNumber - 1}); // Учитываем нулевую индексацию
    };

    const handlePageSizeChange = (pageSize: number) => {
        setFilters({...filters, pageSize});
    };

    const columns: TableColumnDefinition<BPasswords>[] = [
        createTableColumn({
            columnId: "browser",
            renderHeaderCell: () => "browser",
            renderCell: (item) => (
                <TableCellLayout>{item.browserName}</TableCellLayout>
            ),
        }),
        createTableColumn({
            columnId: "url",
            renderHeaderCell: () => "url",
            renderCell: (item) => (
                <TableCellLayout>{item.url}</TableCellLayout>
            ),
        }),
        createTableColumn({
            columnId: "login",
            renderHeaderCell: () => "login",
            renderCell: (item) => (
                <TableCellLayout>{item.login}</TableCellLayout>
            ),
        }),
        createTableColumn({
            columnId: "password",
            renderHeaderCell: () => "password",
            renderCell: (item) => (
                <TableCellLayout>{item.passwordValue}</TableCellLayout>
            ),
        }),
        createTableColumn({
            columnId: "delete",
            renderHeaderCell: () => "",
            renderCell: (item) => (
                <Button appearance="subtle" onClick={() => handleDeletePass(item.id)}>
                    Delete
                </Button>
            ),
        }),
    ];

    return (
        <div>
            <h2>Tag Management</h2>
            <div>
                <Select
                    value={String(filters.pageSize)}
                    onChange={(e, data) => handlePageSizeChange(parseInt(data.value || '0'))}
                >
                    <option>10</option>
                    <option>25</option>
                    <option>50</option>
                    <option>75</option>
                    <option>100</option>
                    <option>200</option>
                    <option>300</option>
                </Select>
                <Input
                    type="text"
                    placeholder="Type your keyword here"
                    value={filters.keyword}
                    onChange={(e, data) => setFilters({...filters, keyword: data.value || ''})}
                />
                <Button onClick={() => fetchTags()}>Search</Button>
            </div>
            <div>
                <Button appearance="subtle" onClick={() => {/* Логика удаления паролей */
                }}>
                    Delete
                </Button>
            </div>
            <DataGrid items={password} columns={columns}>
                <DataGridHeader>
                    <DataGridRow
                        selectionCell={{
                            checkboxIndicator: {"aria-label": "Select all rows"},
                        }}>
                        {({renderHeaderCell}) => (
                            <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
                        )}
                    </DataGridRow>
                </DataGridHeader>
                <DataGridBody>
                    {({item, rowId}) => (
                        <DataGridRow
                            key={rowId}
                            selectionCell={{
                                checkboxIndicator: {"aria-label": "Select row"},
                            }}

                        >
                            {({renderCell}) => (
                                <DataGridCell as={"div"}>{renderCell(item)}</DataGridCell>
                            )}
                        </DataGridRow>
                    )}
                </DataGridBody>
            </DataGrid>
            <PaginationButtons
                currentPage={paging.pageNumber + 1 ?? 1}  // Отображаем текущую страницу начиная с 1
                totalPages={paging.totalPages ?? 1}
                totalCount={paging.totalItems ?? 1}
                onNextClick={() => handlePageChange(paging.pageNumber + 2)}  // Страница +1, так как нулевая индексация
                onPrevClick={() => handlePageChange(paging.pageNumber)}
            />
        </div>
    );
};

export default PasswordForm;
