import React, {useCallback, useEffect, useState} from 'react';
import {Badge, Card, Label, Subtitle2} from '@fluentui/react-components';
import UseStyle from '../../forms/BuilderForm/BuilderFormStyles';
import {useServices, useStore} from '../../hooks';
import {BuildRequestStatesDto} from '../../models/common';
import {ErrorCode} from '../../models/shared/ErrorCode';
import {useToast} from "../../utils/ToastProvider";
import {GetMessageByCode} from "../../utils";
import {BuilderForm} from "../../forms/BuilderForm";
import {observer} from "mobx-react-lite";
import {BuilderRequestState} from "../BuilderRequestState";

/**
 * форма статуса билдера
 */
const BuilderManaging: React.FC = () => {
    const styles = UseStyle();
    const [initialState, setInitialState] = useState<boolean>(true);
    const services = useServices();
    const {showToast} = useToast();
    const store = useStore();

    const fetchBuilderInfo = useCallback(async () => {
        try {
            const response = await services.builderService.getBuilderInfo();
            store.builderStore.requestState = response.currentState;
            store.builderStore.remainingBuild = response.buildRemainsCount;
        } catch (error: any) {
            const errorMessage = error.code === ErrorCode.CustomErrorMessage
                ? error.message
                : GetMessageByCode(error.code);

            showToast('Failed to fetch builder info'
                , errorMessage
                , "error");
        }
    }, [services.builderService, showToast]);

    /**
     * Эффект для первой загрузки данных
     */
    useEffect(() => {
        if (initialState) {
            fetchBuilderInfo();
            setInitialState(true);
        }
    }, [initialState, fetchBuilderInfo]);

    /**
     * получение текущего статуса билдера
     */
    useEffect(() => {
        if ((store.builderStore.requestState.state === BuildRequestStatesDto.Waiting)
            || (store.builderStore.requestState.state === BuildRequestStatesDto.Processing)) {
            fetchBuilderInfo();
            // таймер обновления статуса
            const intervalId = setInterval(fetchBuilderInfo, 5000);
            return () => clearInterval(intervalId);
        }
    }, [fetchBuilderInfo, store.builderStore.requestState.state]);

    const showCreateNewBuild = () => {
        if (store.builderStore.requestState.state === BuildRequestStatesDto.None) {
            return <BuilderForm/>
        }
    }

    const showLastRequestState = () => {
        if (store.builderStore.requestState.state !== BuildRequestStatesDto.None) {
            return <BuilderRequestState/>
        }
    }

    return (
        <Card className={styles.cardContainer}>
            <Subtitle2>Builder</Subtitle2>
            <div>
                <Label>Remaining Builds:</Label>
                <Badge style={{marginLeft: '1em'}}>{store.builderStore.remainingBuild}</Badge>
                <Label style={{marginLeft: '1em'}}>Default password:</Label>
                <Badge color={'important'}
                       style={{marginLeft: '1em'}}>acr</Badge>
            </div>
            <div>

            </div>
            {showLastRequestState()}
            {showCreateNewBuild()}
        </Card>
    );
};

/**
 * Имя отображаемое при отладке
 */
BuilderManaging.displayName = 'BuilderManaging';

export default observer(BuilderManaging);